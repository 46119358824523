import { getClaimableItemDetails } from '@/core/helpers/battlePath.js';
import { fetchAndUpdateUser } from '@/flows/Authentication/helpers/AuthHelpers';
import getBattlePass from '@/student/static-json/BattlePassV2.json';
import SecureApi from '@/flows/Authentication/services/SecureApi';
import { nameToPlayerName } from '@/core/helpers/utils';
import { fromSession, toSession, delSession } from '@/core/helpers/utils.cache';
import { countBy } from 'lodash';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';
import moment from 'moment/moment';

/**
 *  User store module contains all user related state, getters, mutations and actions.
 */
const store = {
    namespaced: true,
    state: {
        guestId: null,
        loggedIn: false,
        battlePass: getBattlePass,
        claimableRewards: [],
        playerName: null,
        activeClassCode: '-1',
        /**
         * Folds full class objects.
         * Populated by `HostActions::getClassPage` that is called on post-login
         * for all classes in `user.studentInfo.classLists`.
         * Use the latter if you need only name and code.
         */
        classes: {},
    },
    getters: {
        battlePass: (state) => {
            return state.battlePass;
        },
        user: (state, getters, rootState, rootGetters) => rootGetters.user,
        uid: (state, getters) => getters.user?.userId || null,
        clientId: (state) => state.userId || state.guestId,
        playerName: (state, getters) => {
            if (getters.user && getters.user.playerName) {
                return getters.user.playerName;
            }

            if (state.playerName) {
                return state.playerName;
            }

            if (!getters.uid) {
                return null;
            }

            return nameToPlayerName(
                getters.user.firstName || '',
                getters.user.lastName || '',
            );
        },
        inAbTests: (state, getters) => {
            const user = getters.user;

            return user && user.abTests && Object.keys(user.abTests).length > 0;
        },
        // easy check of been in required abTest
        inAbTest: (state, getters) => (test, value) => {
            if (!getters.inAbTests) {
                return false;
            }

            if (typeof value !== 'undefined') {
                return getters.user.abTests[test] === value;
            }

            return getters.user.abTests[test];
        },
        inOldDesktopTests: (state, getters) => {
            if (!getters.inAbTests) {
                return false;
            }

            const { abTests } = getters.user;

            return (
                abTests.teacherDashboard1 ||
                abTests.teacherDashboard2 ||
                abTests.teacherDashboard3 ||
                abTests.teacherDashboard4 ||
                abTests.teacherDashboard5 ||
                abTests.teacherDashboard6 ||
                abTests.teacherDashboard7 ||
                abTests.teacherDashboard8 ||
                abTests.teacherDashboard9
            );
        },
        inNewDesktopTests: (state, getters) => {
            if (!getters.inAbTests) {
                return false;
            }
            const { abTests } = getters.user;

            return (
                abTests.teacherDashboard10 ||
                abTests.teacherDashboard11 ||
                abTests.teacherDashboard12
            );
        },
        inAbTestsLiveLobby: (state, getters) => {
            if (window.innerWidth > 800 || !getters.inAbTests) {
                return false;
            }

            return (
                getters.inAbTest('mobileActivation7') ||
                getters.inOldDesktopTests ||
                getters.inNewDesktopTests
            );
        },
        // common tests
        isStudent: (state, getters) => {
            return getters.user?.role === 'student';
        },
        isTeacher: (state, getters) => {
            return getters.user?.role === 'teacher';
        },
        isParent: (state, getters) => {
            return getters.user?.role === 'parent';
        },
        isFromUS: (state, getters) => {
            const isLocalUserFromUSA = !!localStorage
                .getItem('country_code3')
                ?.includes('USA');

            return getters.user?.country?.includes('USA') || isLocalUserFromUSA;
        },
        isUSTeacher: (state, getters) => {
            return getters.isTeacher && getters.isFromUS;
        },
        getDailyRewards: (state, getters) => {
            return getters.user?.studentInfo?.rewards?.daily;
        },
        getClaimRewardsCount: (state) => {
            return state.claimableRewards?.length || 0;
        },
        getClaimableRewards: (state) => {
            return state.claimableRewards;
        },
        getClaimableItemData: (state) => {
            let lastOneClaimableItem =
                state.claimableRewards[state.claimableRewards.length - 1];

            return getClaimableItemDetails(lastOneClaimableItem);
        },
        getClassCode: (state, getters) => {
            const code = state.activeClassCode;

            const classCodes = Object.keys(
                getters.user?.studentInfo?.classLists || {},
            );

            if (code && code !== '-1' && classCodes.includes(code)) {
                return code;
            }

            return classCodes.length ? classCodes[classCodes.length - 1] : null;
        },
        classes: (state) => {
            return state.classes;
        },
        activeClass: (state, getters) => {
            return state.classes[getters.getClassCode];
        },
        classListViewModel(state, getters) {
            const classesObj = getters.user.studentInfo?.classLists || {};

            const classesArr = Object.keys(classesObj).map((cl) => ({
                classCode: cl,
                name: classesObj[cl],
                secondary: '',
            }));

            const duplicateMap = countBy(classesArr, 'name');

            classesArr.forEach((cl) => {
                if (duplicateMap[cl.name] > 1) cl.secondary = cl.classCode;
            });

            return classesArr;
        },
        numberOfClasses: (state, getters) => {
            return Object.keys(getters.classListViewModel).length;
        },
        chest: (state, getters) => getters.user.studentInfo?.chest,
        stripeProductOffer: (state, getters) => {
            if (getters.user?.paymentInfo?.stripe?.productOffer)
                return getters.user.paymentInfo.stripe.productOffer;
            else
                return {
                    name: getters.user?.priceTestVersion.name,
                    productId:
                        getters.user?.priceTestVersion?.products[0]?.stripe
                            ?.product,
                    prices: getters.user?.priceTestVersion?.products?.map(
                        (el) => el.stripe,
                    ),
                };
        },
    },
    mutations: {
        userId: (state, next) => {
            state.userId = next;
        },
        guestId: (state, next) => {
            state.guestId = next;
        },
        loggedIn: (state, next) => {
            state.loggedIn = next;
        },
        setClaimRewardsCount: (state, next) => {
            state.claimRewardsCount = next;
        },
        setClaimableRewards: (state, next) => {
            state.claimableRewards = next;
        },
        playerName: (state, next) => {
            console.log('commit:playerName', next);

            state.playerName = next;

            if (!next) {
                return delSession('playerName');
            }

            toSession('playerName', next);
        },
        activeClassCode: (state, next) => {
            state.activeClassCode = next;
        },
        userClass: (state, next) => {
            if (next.classCode) state.classes[next?.classCode] = next;
        },
        resetStateToDefault: (state) => {
            state.classes = {};
            state.activeClassCode = '-1';
        },
    },
    actions: {
        init: async (store) => {
            // console.log('store::user init');
            const urlParams = new URLSearchParams(document.location.search);

            // You can access specific parameters:
            const authToken = urlParams.get('authToken') || null;
            const userDataToken = urlParams.get('userDataToken') || null;
            if (authToken && userDataToken) {
                console.log('store::user authToken restored from url param');
                store.dispatch('v2/reset', null, { root: true });
                localStorage.setItem('userDataToken', userDataToken);
                localStorage.setItem('authToken', authToken);
            }
            const loggedIn = await store.dispatch('checkJwt', null, {
                root: true,
            });
            store.commit('loggedIn', loggedIn);
            const playerName = fromSession('playerName');

            if (playerName) {
                store.commit('playerName', playerName);
            }

            if (!loggedIn) {
                const guestIn = await store.dispatch('checkGuestJwt', null, {
                    root: true,
                });

                if (!guestIn) {
                    await store.dispatch('generateGuestJwt', null, {
                        root: true,
                    });
                }
                // return;
            } else {
                await store.dispatch('setupDailyClaimable');
            }
            /**/
            console.log(
                'store::user initialized',
                {
                    ...store.getters.user,
                },
                playerName,
            );
            /**/
        },
        saveCache: () => {
            // console.log('user save store', store.state);
            // should save to localStorage
        },
        loadCache: () => {
            // console.log('user load store', store.state);
            // should load from localStorage
        },
        clearCache: () => {
            // console.log('user load store', store.state);
            // should clear localStorage
        },
        logIn: async (store) => {
            store.commit('loggedIn', true);
            store.commit(
                'activeClassCode',
                store.getters.user?.activeClass?.classCode,
            );

            console.debug('store::user::logIn', store.getters.user);

            store.commit('playerName', store.getters.user.playerName);
            store.commit('resetStateToDefault');

            await store.dispatch('setupDailyClaimable');
        },
        // clearPlayerName flag used to keep player name for guest
        // on regular page refresh, and clear name on loggedIn logout
        logOut: async (store, clearPlayerName = false) => {
            store.commit('loggedIn', false);
            console.debug('store::user::logOut', store.getters.user);

            await store.dispatch('stopAudio', true, { root: true });

            if (clearPlayerName) store.commit('playerName', null);
        },
        // currently - just wrapper around fetchAndUpdate
        reload: async (store) => {
            console.debug('store:user.reload');
            await fetchAndUpdateUser(store.state.user);
        },
        update(store, data) {
            if (!data) return;

            const activeClass =
                data.activeClass || store.getters.user?.activeClass;

            store.dispatch('setActiveClassCode', {
                classCode: activeClass?.classCode,
            });

            return store.dispatch(
                'setUserInfoToStore',
                {
                    ...store.getters.user,
                    ...(data?.role ? data : {}),
                    ...(data?.accountInfo || {}),
                    ...(data?.teacherInfo || {}),
                    ...(data?.studentInfo
                        ? { studentInfo: data?.studentInfo }
                        : {}),
                    ...(data?.paymentInfo
                        ? { paymentInfo: data?.paymentInfo }
                        : {}),
                    ...(data?.parentInfo
                        ? { parentInfo: data?.parentInfo }
                        : {}),
                    activeClass,
                },
                { root: true },
            );
        },
        async updateProfile(store, payload) {
            try {
                const response = await SecureApi().patch(
                    `/user/profile`,
                    payload,
                );
                const { success, error, data } = response.data;
                if (success) {
                    const user = store.dispatch('update', data);
                    return { success, user };
                } else {
                    console.error(`[updateProfile] server error: ${error}`);
                }
            } catch (err) {
                console.error(`[updateProfile] API error: ${err}`);
            }
        },
        async setupDailyClaimable(store) {
            if (
                store.getters.isStudent &&
                !store.rootState.user?.studentInfo?.rewards
            ) {
                const response = await SecureApi().post(
                    'solo-track/rewards/daily/unlock',
                );

                const { success, error, data } = response.data;
                if (success) {
                    store.dispatch('update', { studentInfo: data });
                } else {
                    console.error(
                        `[setupDailyClaimable] server error: ${error}`,
                    );
                }
            }
        },
        async setClaimable(store) {
            const myCurrentLevel =
                store.rootGetters['v2/homegame/getMyCurrentLevel'];

            const battlePassUnlocked = ['active', 'trialing'].includes(
                store.rootState.user?.subscriptionStatus,
            );
            const claimableRewards =
                store.getters.battlePass
                    .filter((group) => group.level <= myCurrentLevel)
                    .map((group) =>
                        group.items.map((item) => ({
                            ...item,
                            level: group.level,
                        })),
                    )
                    .flatMap((list) => list)
                    .filter((item) => {
                        const battlePassFilter = battlePassUnlocked
                            ? true
                            : !item.battlePass;

                        const claimedFilter = store.rootGetters.isClaimedItem(
                            item.id,
                        );

                        const unClaimedFilter = (
                            store.rootState.user?.studentInfo?.battlepass
                                ?.unclaimedRewards || []
                        ).includes(item.id);

                        return (
                            (unClaimedFilter || battlePassFilter) &&
                            !claimedFilter
                        );
                    }) || [];
            store.commit('setClaimableRewards', claimableRewards);

            if (!claimableRewards.length) return;

            const response = await SecureApi().post(
                'battlepass/rewards/unlock',
                claimableRewards,
            );
            const { success, error, data } = response.data;

            if (success) {
                const user = store.dispatch('update', {
                    studentInfo: data,
                });
                return { success, user };
            } else {
                console.error(`[setClaimable] server error: ${error}`);
            }
        },
        async setActiveClassCode(store, { classCode, className, update }) {
            store.commit(
                'activeClassCode',
                classCode || store.getters['getClassCode'] || '-1',
            );

            if (update) {
                await SecureApi().post('user/redis-info', {
                    activeClass: {
                        classCode,
                        className,
                    },
                });

                store.dispatch('update', {
                    activeClass: {
                        classCode,
                        className,
                    },
                });
            }
        },
        async setGrade(store, grade) {
            try {
                const response = await SecureApi().post(
                    `/user/set-grade/${grade}`,
                );
                const { success, error, data } = response.data;
                if (success) {
                    store.dispatch('update', { studentInfo: data });

                    return data;
                } else {
                    console.error(`[setGrade] server error: ${error}`);
                }
            } catch (err) {
                console.error(`[setGrade] request error: ${err}`);
            }
        },
        async setAvatar(store, { avatar, customAvatar }) {
            try {
                const response =
                    avatar === 'custom'
                        ? await SecureApi().post(
                              `home-game-v10SimpleTreeSocial/set-avatar/${avatar}`,
                              customAvatar ? customAvatar : { skin: 'skin_1' },
                          )
                        : await SecureApi().post(
                              `home-game-v10SimpleTreeSocial/set-avatar/${avatar}`,
                          );

                const { success, data, error } = response.data;

                if (success && data) {
                    store.dispatch('update', { studentInfo: data });

                    return data;
                } else {
                    console.error(`[setAvatar] server error: ${error}`);
                }
            } catch (err) {
                console.error(`[setAvatar] request error: ${err}`);
            }
        },
        async setBackground(store, background) {
            try {
                const response = await SecureApi().post(
                    `home-game-v10SimpleTreeSocial/set-background/${background}`,
                );

                const { success, data, error } = response.data;

                if (success && data) {
                    new TrackingService().track(
                        EVENTS.CHANGED_BACKGROUND,
                        background,
                    );

                    store.dispatch('update', { studentInfo: data });

                    return data;
                } else {
                    console.error(`[setBackground] server error: ${error}`);
                }
            } catch (err) {
                console.error(`[setBackground] request error: ${err}`);
            }
        },
        async setFrame(store, frame) {
            try {
                const response = await SecureApi().post(
                    `home-game-v10SimpleTreeSocial/set-frame/${frame}`,
                );

                const { success, data, error } = response.data;

                if (success && data) {
                    new TrackingService().track(EVENTS.CHANGED_FRAME, frame);

                    store.dispatch('update', { studentInfo: data });

                    return data;
                } else {
                    console.error(`[setFrame] server error: ${error}`);
                }
            } catch (err) {
                console.error(`[setFrame] request error: ${err}`);
            }
        },
        async fetchAndStoreUserInfo(store, params) {
            try {
                const response = await SecureApi().get('user/info', {
                    params,
                });

                const { data, success, error } = response.data;

                if (success && data.length) {
                    if (!params.userIds || params.userIds === store.getters.uid)
                        store.dispatch('update', data[0]);

                    return data;
                } else if (error) {
                    console.error(
                        `[fetchAndStoreUserInfo] server error: ${error}`,
                    );
                }
            } catch (err) {
                console.error(`[fetchAndStoreUserInfo] request error: ${err}`);
            }
        },
        async fetchStudentInfo(store) {
            try {
                const response = await SecureApi().get(`/user/student-info`);

                const { success, error, data } = response.data;

                if (success) {
                    store.dispatch('update', { studentInfo: data });
                } else {
                    console.error(`[fetchStudentInfo] server error: ${error}`);
                }
            } catch (err) {
                console.error(`[fetchStudentInfo] request error: ${err}`);
            }
        },
        async joinClass(store, classCode) {
            try {
                const response = await SecureApi().post(
                    `class-lists/${classCode.replace(
                        /\D/g,
                        '',
                    )}/student/set-class-code`,
                );
                const { data, success, error } = response.data;
                if (success && data) {
                    store.dispatch('update', data);
                    store.commit('userClass', {
                        ...data.classroom,
                        commitedAt: moment(),
                    });

                    new TrackingService().track(EVENTS.STUDENT_JOINED_CLASS, {
                        classCode,
                        teacherName: data.teacherName,
                        teacherEmail: data.teacherEmail,
                        className: data.classroom?.className,
                    });

                    return data.classroom;
                } else if (error) {
                    console.error(`[setGrade] server error: ${error}`);
                    return { error };
                }
            } catch (err) {
                console.error(`[joinClass] request error: ${err}`);
            }
        },
    },
};

export default store;
