/* global mixpanel */
import { AUTH_MP_METHODS } from './AuthEvents';

export const USER_MP_EVENTS = {
    ADD_AB_TEST: 'addAbTest',
    SET_FLAG: 'setFlag',
    SUBSCRIBE_ATTEMPT: 'subscribeAttempt',
    PARENT_SUBSCRIBE_FOR_KID_ATTEMPT:
        'parentTriesToGetPremiumForKidViaParentAccount',
    PARENT_SUBSCRIBE_FOR_KID_FAIL:
        'parentFailsToGetPremiumForKidViaParentAccount',
    PARENT_INFO_MODAL_OPENED: 'parentInfoModalOpened',
    PARENT_INFO_MODAL_CLOSED: 'parentInfoModalClosed',
    VIEWED_DASHBOARD: 'viewedDashboard',
    CLICKED_PLAYED_AGAIN: 'clickedPlayAgain',
    CLICKED_CHOOSE_GAME_MODE: 'clickedChooseGameMode',
    CHOSE_GAME_MODE: 'choseGameMode',
    CLICKED_CONFIGURE: 'clickedConfigure',
    CHOSE_TOPIC: 'choseTopic',
    BROWSE_TOPIC: 'browseTopic',
    BROWSE_SKILL: 'browseSkill',
    INITIATE_GAME: 'initiateGame',
    INITIATE_ASSIGNMENT: 'initiateAssignment',
    CHOSE_TOPIC_COMING_SOON: 'choseTopicComingSoon',
    CHOSE_LEVEL: 'choseLevel',
    CLICKED_VIEW_MORE_TOPICS: 'clickedViewMoreTopics',
    CLICKED_VIEW_LESS_TOPICS: 'clickedViewLessTopics',
    CLICKED_LIVE_GAME_BTN: 'clickedLiveGameBtn',
    CLICKED_SEND_EMAIL_FOR_DESKTOP: 'clickedSendEmailReminder',
    CLICKED_SPG_BTN: 'clickedSPGBtn',
    VIEWED_GAME_MISTAKES: 'viewedGameMistakes',
    CLICKED_QUIT_GAME: 'clickedQuitGame',
    CLICKED_ON_LOGO: 'clickedOnLogo',
    RETURN_TO_DASHBOARD: 'returnToDashboard',
    TEACHING_GRADES: 'teachingGrades',
    VIEWED_MY_REPORTS: 'viewedMyReports',
    CLICKED_VIEW_GAME_REPORT: 'clickedViewGameReport',
    CLICKED_DELETE_PAST_REPORT: 'clickedDeletePastReport',
    VIEWED_GAME_REPORT: 'viewedGameReport',
    VIEWED_MY_POINTS_PAGE: 'viewedMyPointsPage',
    VIEWED_GLOBAL_LEADERBOARD: 'viewedGlobalLeaderboard',
    VIEWED_LOCAL_LEADERBOARD: 'viewedLocalLeaderboard',
    VIEWED_TEAMS_LEADERBOARD: 'viewedTeamsLeaderboard',
    VIEWED_COUNTRIES_LEADERBOARD: 'viewedCountriesLeaderboard',
    VIEWED_COUNTRIES_PLAYER_LEADERBOARD: 'viewedCountriesPlayerLeaderboard',
    VIEWED_LIVE_GAMES_LIST_PAGE: 'viewedLiveGamesListPage',
    VIEWED_REWARDS_PAGE: 'viewedRewardsPage',
    COPIED_SHARE_LINK_IN_REWARDS_PAGE: 'copiedShareLinkInRewardsPage',
    COPIED_TEAM_LINK_IN_REWARDS_PAGE: 'copiedTeamLinkInRewardsPage',
    VIEWED_TEAM_IS_FULL_PAGE: 'viewedTeamIsFullPage',
    CHOSE_SINGLE_PLAYER: 'choseSinglePlayer',
    CHOSE_PLAY_WITH_FRIENDS: 'chosePlayWithFriends',
    UPDATE_PROFILE: 'updateProfile',
    VIEWED_PLAYER_STATS: 'viewedPlayerStats',
    STUDENT_JOINED_CLASS: 'studentJoinedClass',
    STUDENT_SET_AGE: 'studentSetAge',
    PARENT_LINKED_CHILD: 'parentAddedAChild',
    CLICKED_VIEW_CHILD_PROGRESS: 'clickedViewChildProgress',
    VIEWED_STUDENT_PROGRESS_VIEW: 'viewedStudentProgress',
    SELECTED_SKILL_ON_DASHBOARD: 'teacherSelectedSkillOnDashboard',
    LIVEGAME_EXPERIENCE_RATE: 'liveGameExperienceRate',
    LIVEGAME_EXPERIENCE_FEEDBACK: 'liveGameExperienceFeedback',
    LIVEGAME_TECH_EXPERIENCE_RATE: 'liveGameTechExperienceRate',
    LIVEGAME_TECH_EXPERIENCE_FEEDBACK: 'liveGameTechExperienceFeedback',
    SWITCHED_DASHBOARD: 'switchedDashboard',
    RECENTLY_PLAYED_LOADED: 'recentlyPlayedLoaded',
    WARMUP_GAMES_LOADED: 'warmupGamesLoaded',
    WARMUP_GAMES_REMOVED: 'warmupGamesRemoved',
    CREATED_LIVE_RECENTLY_PLAYED: 'createdLiveRecentlyPlayed',
    CREATED_INDIVIDUAL_RECENTLY_PLAYED: 'createdIndividualRecentlyPlayed',
    SEE_REPORT_RECENTLY_PLAYED: 'seeReportRecentlyPlayed',
    SEE_REPORT_SUGGESTED_GAMES: 'seeReportSuggestedGames',
    CREATED_LIVE_WARMUP_GAMES: 'createdLiveWarmupGames',
    INVITE_TO_UNLOCK_MODAL_SEEN: 'seesInviteToUnlockModal',
    INVITE_TO_UNLOCK_MODAL_CLOSED: 'closesInviteToUnlockModal',
    INVITE_TO_UNLOCK_MODAL_CLICKED_SUBMIT: 'submitsInviteToUnlockModal',
    INVITE_TO_UNLOCK_MODAL_USER_UPGRADED:
        'userIsUpgradedAfterSubmittingUnlockModal',
    ACCOUNT_DELETED: 'accountDeleted',
    SOCIAL_ENLARGED_VIEW_CLICKED: 'socialEnlargedViewClicked',
    SOCIAL_SHARED_TO_FB: 'socialSharedToFB',
    STUDENT_SAW_TBL_END_GAME: 'studentSawTblEndGame',
    STUDENT_SAW_MORE_99MATH: 'studentSawMore99Math',
    OPEN_MOBILE_APP: 'openMobileApp',
    CONTINUE_WITH_BROWSER: 'continueWithBrowser',
    CLICKED_SUPPORT: 'clickedSupport',
    CLICKED_SUPPORT_TYPE: 'clickedSupportType',
};

export const USER_MP_METHODS = {
    addAbTest({ testId, testValue }) {
        const map = {};
        map[`AbTest: ${testId}`] = testValue;
        mixpanel.people.set(map);
    },
    setFlag({ flagName, flagValue }) {
        const map = {};
        map[`${flagName}`] = flagValue;
        mixpanel.track('Flipped a flag', {
            Flag: flagName,
            Value: flagValue,
        });
        mixpanel.people.set(map);
    },
    subscribeAttempt({
        success,
        paymentMethod,
        cameFrom,
        productName,
        productPrice,
        productInterval,
    }) {
        mixpanel.track('Attempted to subscribe', {
            Success: success,
            'Payment method': paymentMethod,
            'Came from': cameFrom,
            'Product name': productName,
            'Product price': productPrice,
            'Product interval': productInterval,
        });
    },
    parentTriesToGetPremiumForKidViaParentAccount({
        success,
        paymentMethod,
        cameFrom,
        productName,
        productPrice,
        productInterval,
    }) {
        mixpanel.track(
            'Parent tries to get premium for kid via parent account',
            {
                Success: success,
                'Payment method': paymentMethod,
                'Came from': cameFrom,
                'Product name': productName,
                'Product price': productPrice,
                'Product interval': productInterval,
            },
        );
    },
    parentFailsToGetPremiumForKidViaParentAccount({
        success,
        paymentMethod,
        cameFrom,
        productName,
        productPrice,
        productInterval,
    }) {
        mixpanel.track(
            'Parent fails to get premium for kid via parent account',
            {
                Success: success,
                'Payment method': paymentMethod,
                'Came from': cameFrom,
                'Product name': productName,
                'Product price': productPrice,
                'Product interval': productInterval,
            },
        );
    },
    parentInfoModalOpened() {
        mixpanel.track('Parent info modal opened');
    },
    parentInfoModalClosed() {
        mixpanel.track('Parent info modal closed');
    },
    viewedDashboard() {
        mixpanel.track('Viewed dashboard');
    },
    clickedPlayAgain({ gameMode, taskType, taskScale }) {
        mixpanel.track('Clicked "Play Again"', {
            'Game mode': gameMode,
            'Task type': taskType,
            'Task scale': taskScale,
        });
    },
    clickedChooseGameMode() {
        mixpanel.track('Clicked "Choose game mode"');
    },
    choseGameMode(mode) {
        mixpanel.track('Chose game mode', {
            'Game mode': mode,
        });
    },
    clickedConfigure() {
        mixpanel.track('Clicked configure');
    },
    choseTopic(taskType) {
        mixpanel.track('Chose topic', {
            'Task type': taskType,
        });
    },
    browseTopic(taskType) {
        mixpanel.track('Browse topic', {
            'Task type': taskType,
        });
    },
    browseSkill({ taskType, taskScale, skillName }) {
        mixpanel.track('Browse skill', {
            'Task type': taskType,
            'Task scale': taskScale,
            'Skill name': skillName,
        });
    },
    initiateGame({ teacherUi = 'old', multiple = false }) {
        mixpanel.track('Initiate game', {
            'Teacher UI Version': teacherUi,
            'Multiple skills': multiple,
        });
    },
    initiateAssignment({ teacherUi = 'old' }) {
        mixpanel.track('Initiate Assignment', {
            'Teacher UI Version': teacherUi,
        });
    },
    choseTopicComingSoon(taskType) {
        mixpanel.track('Chose topic coming soon', {
            'Task type': taskType,
        });
    },
    choseLevel({ taskScale, taskType }) {
        mixpanel.track('Chose level', {
            'Task scale': taskScale,
            'Task type': taskType,
        });
    },
    clickedViewMoreTopics() {
        mixpanel.track('Viewed more topics on dashboard');
    },
    clickedViewLessTopics() {
        mixpanel.track('Viewed less topics on dashboard');
    },
    clickedLiveGameBtn() {
        mixpanel.track('Clicked Live Game Btn on Dashboard');
    },
    clickedSPGBtn() {
        mixpanel.track('Clicked Self Paced Game Btn on Dashboard');
    },
    clickedSendEmailReminder() {
        mixpanel.track('Sent email reminder to use on desktop');
    },
    viewedGameMistakes(mistakes) {
        mixpanel.track('Viewed mistakes', {
            mistakes: mistakes,
        });
    },
    clickedQuitGame({ gameCode, routeName }) {
        mixpanel.track('Clicked quit game', {
            Gamecode: gameCode,
            'Route name': routeName,
        });
    },
    clickedOnLogo({ gameCode, routeName }) {
        mixpanel.track('Clicked logo', {
            Gamecode: gameCode,
            'Route name': routeName,
        });
    },
    returnToDashboard() {
        mixpanel.track('Returned to dashboard');
    },
    teachingGrades(teachingGradesData) {
        mixpanel.people.set({ 'Teaching Grades': teachingGradesData });
    },
    viewedMyReports() {
        mixpanel.track('Viewed My Reports');
    },
    clickedViewGameReport() {
        mixpanel.track('Clicked View Game Report');
    },
    clickedDeletePastReport({ gameCode, gameMode, playerCount, accuracy }) {
        mixpanel.track('Deleted past report', {
            Gamecode: gameCode,
            Gamemode: gameMode,
            'Player count': playerCount,
            'Class accuracy (%)': accuracy,
        });
    },
    viewedGameReport({
        variant,
        playerCount,
        className,
        taskType,
        taskScale,
        classScoreChange,
    }) {
        mixpanel.track('Viewed game report', {
            Variant: variant,
            'Player count': playerCount,
            'Played with class': className ? true : false,
            'Class name': className,
            'Task Type': taskType,
            'Task Scale': taskScale,
            'Class score change (%)': classScoreChange,
        });
    },
    viewedMyPointsPage() {
        mixpanel.track('My points view');
    },
    viewedGlobalLeaderboard({ type }) {
        mixpanel.track('Viewed Global leaderboard', {
            'Leaderboard type': type,
        });
    },
    viewedLocalLeaderboard({ type }) {
        mixpanel.track('Viewed Local leaderboard', {
            'Leaderboard type': type,
        });
    },
    viewedTeamsLeaderboard({ type }) {
        mixpanel.track('Viewed Teams leaderboard', {
            'Leaderboard type': type,
        });
    },
    viewedCountriesLeaderboard({ type }) {
        mixpanel.track('Viewed country leaderboard', {
            'Leaderboard type': type,
        });
    },
    viewedCountriesPlayerLeaderboard({ country }) {
        mixpanel.track('Viewed countrys player leaderboard', {
            'Leaderboard country': country,
        });
    },
    viewedLiveGamesListPage() {
        mixpanel.track('Viewed Live games list page');
    },
    viewedRewardsPage() {
        mixpanel.track('Viewed rewards page');
    },
    copiedShareLinkInRewardsPage() {
        mixpanel.track('Copied share link in rewards page');
    },
    copiedTeamLinkInRewardsPage() {
        mixpanel.track('Copied team link in rewards page');
    },
    viewedTeamIsFullPage() {
        mixpanel.track('Viewed team is full page');
    },
    choseSinglePlayer() {
        mixpanel.track('Chose "Single player"');
    },
    chosePlayWithFriends() {
        mixpanel.track('Chose "Play with friends"');
    },
    updateProfile(data) {
        mixpanel.people.set({
            Role: data.role,
            'Teaching Grades': data.teachingGrades,
            $name: data.firstName + ' ' + data.lastName,
            'Receive SelfPaced Game reports via Email':
                data.receiveSelfPacedGameReportsViaEmail,
            'Receive Live Game reports via Email':
                data.receiveLiveGameReportsViaEmail,
        });
    },
    viewedPlayerStats() {
        mixpanel.track('Viewed Player Stats Page');
    },
    studentJoinedClass({ classCode, teacherName, teacherEmail, className }) {
        mixpanel.track('Joined a class', {
            'Class code': classCode,
        });
        if (teacherName && teacherEmail && className) {
            mixpanel.people.set({
                'Teacher name': teacherName,
                'Teacher email': teacherEmail,
                'Class name': className,
            });
        }
    },
    studentSetAge({ age, signInfo, user }) {
        this.user = user;
        AUTH_MP_METHODS.signUp.bind(this)({ ...signInfo });
        /**
         * Apply setTimeout to force sending "Set age of a student" event
         * after sign up mixpanel event.
         */
        window.setTimeout(() => {
            mixpanel.track('Set age of a student', { Age: age });
        }, 500);
    },
    parentAddedAChild({ studentAccess }) {
        mixpanel.track('Linked a child', {
            'Child access info': studentAccess,
        });
    },
    clickedViewChildProgress(studentId) {
        mixpanel.track('Clicked view child progress', {
            'Student user id': studentId,
        });
    },
    viewedStudentProgress(studentId) {
        mixpanel.track('Viewed Student progress view', {
            'Student user id': studentId,
        });
    },
    teacherSelectedSkillOnDashboard({
        taskType,
        taskScale,
        skillName,
        location,
    }) {
        mixpanel.track('Selected skill on dashboard', {
            'Task type': taskType,
            'Task scale': taskScale,
            'Skill name': skillName,
            Location: location,
        });
    },
    liveGameExperienceRate(rate) {
        mixpanel.track('Live game rate', {
            'Score click': rate,
        });
    },
    liveGameExperienceFeedback({ comment, score }) {
        mixpanel.track('Live game feedback', {
            'Added comment': comment,
            'Score click': score,
        });
    },
    liveGameTechExperienceRate({ rate, gameCode }) {
        mixpanel.track('Live game tech issues', {
            Answer: rate,
            'Game Code': gameCode,
        });
    },
    liveGameTechExperienceFeedback({ comment, score }) {
        mixpanel.track('Live game tech can call', {
            'Can contact': comment,
            Answer: score,
        });
    },
    switchedDashboard(dashboard) {
        mixpanel.track('Switched dashboard', {
            'Dashboard switched to': dashboard,
        });
        mixpanel.people.set('Dashboard currently seen', dashboard);
    },
    recentlyPlayedLoaded() {
        mixpanel.track('Recently played loaded');
    },
    warmupGamesLoaded() {
        mixpanel.track('Warmup games loaded');
    },
    warmupGamesRemoved() {
        mixpanel.track('Warmup games removed');
    },
    createdLiveRecentlyPlayed() {
        mixpanel.track('Created live game recently played');
    },
    createdIndividualRecentlyPlayed() {
        mixpanel.track('Created individual game recently played');
    },
    seeReportRecentlyPlayed() {
        mixpanel.track('See report recently played');
    },
    seeReportSuggestedGames() {
        mixpanel.track('See report suggested games');
    },
    createdLiveWarmupGames() {
        mixpanel.track('Created live game warmup games');
    },
    seesInviteToUnlockModal() {
        mixpanel.track('Sees Invite to Unlock modal');
    },
    closesInviteToUnlockModal() {
        mixpanel.track('Closes Invite to Unlock modal');
    },
    submitsInviteToUnlockModal() {
        mixpanel.track('Submits Invite to Unlock modal');
    },
    userIsUpgradedAfterSubmittingUnlockModal() {
        mixpanel.track('User is upgraded after Invite to Unlock modal submit');
    },
    accountDeleted() {
        mixpanel.track('User deleted account');
    },
    socialEnlargedViewClicked() {
        mixpanel.track('Social enlarged view clicked');
    },
    socialSharedToFB() {
        mixpanel.track('Social shared to FB');
    },
    studentSawTblEndGame() {
        // mixpanel.track('User end game: student saw TBL end game');
    },
    studentSawMore99Math() {
        mixpanel.track('User end game: student click on "more on 99math');
    },
    openMobileApp() {
        mixpanel.track('Open mobile app clicked');
    },
    continueWithBrowser() {
        mixpanel.track('Continue with browser clicked');
    },
    clickedSupport() {
        mixpanel.track('Clicked on support');
    },
    clickedSupportType(type) {
        mixpanel.track('Clicked on support type', {
            Type: type,
        });
    },
};
